var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card",class:{'is-shadowless': !_vm.isModal}},[_c('div',{staticClass:"card-content"},[_c('header',{staticClass:"mb-5"},[_c('div',{staticClass:"is-flex is-justify-content-space-between is-align-items-center"},[_c('h2',{staticClass:"is-size-6 has-text-primary has-text-weight-bold mr-3"},[_vm._v(_vm._s(_vm.$t('death.death')))]),(_vm.isModal)?_c('div',{on:{"click":function($event){return _vm.$emit('close')}}},[_c('b-icon',{staticClass:"cursor-pointer",attrs:{"icon":"close"}})],1):_vm._e()]),_c('div',[_c('p',{staticClass:"has-text-secondary mb-3"},[_vm._v(_vm._s(_vm.selectedFarm.name))])])]),_c('section',{staticClass:"my-2"},[_c('ValidationObserver',{ref:"form"},[_c('form',{staticClass:"mt-3",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"columns is-tablet"},[_c('div',{staticClass:"column is-half-tablet py-0"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('farm.block'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('farm.block'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('sg-select',{attrs:{"items":_vm.selectedFarm.blocks,"type":"is-secondary is-light","disabled":_vm.defaultValue ? true : false,"label":"block_name","placeholder":((_vm.$t('select')) + " " + (_vm.$t('farm.block'))),"expanded":""},on:{"change":function($event){_vm.form.pond = null}},model:{value:(_vm.form.block),callback:function ($$v) {_vm.$set(_vm.form, "block", $$v)},expression:"form.block"}})],1)]}}])})],1),_c('div',{staticClass:"column is-half-tablet py-0"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('farm.pond'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('farm.pond'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('sg-select',{attrs:{"items":_vm.filteredPonds,"type":"is-secondary is-light","disabled":_vm.defaultValue ? true : false,"label":"name","placeholder":((_vm.$t('select')) + " " + (_vm.$t('farm.pond'))),"expanded":""},on:{"change":_vm.onChangePond},model:{value:(_vm.form.pond),callback:function ($$v) {_vm.$set(_vm.form, "pond", $$v)},expression:"form.pond"}})],1)]}}])})],1),_c('div',{staticClass:"column is-half-tablet py-0"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('date'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-datepicker',{attrs:{"placeholder":_vm.$t('date'),"icon":"calendar-text","locale":"id-ID"},on:{"input":_vm.handleDoc},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}})],1)]}}])})],1),_c('div',{staticClass:"column is-half-tablet py-0"},[_c('b-field',{attrs:{"label":"DOC"}},[_c('b-input',{attrs:{"disabled":""},model:{value:(_vm.doc),callback:function ($$v) {_vm.doc=$$v},expression:"doc"}})],1)],1),_c('div',{staticClass:"column is-full py-0"},[_c('ValidationProvider',{attrs:{"name":"MBW","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"MBW","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"id":"mbw","type":"number","step":"any","placeholder":"0.0"},nativeOn:{"keyup":function($event){_vm.form.weight ? _vm.updateAmount() : _vm.updateWeight()}},model:{value:(_vm.form.mbw),callback:function ($$v) {_vm.$set(_vm.form, "mbw", _vm._n($$v))},expression:"form.mbw"}})],1)]}}])})],1),_c('div',{staticClass:"column is-half-tablet py-0"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('death.shrimp'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('death.shrimp'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"id":"amount","type":"number","step":"any","placeholder":"0.0"},nativeOn:{"keyup":function($event){return _vm.updateWeight.apply(null, arguments)}},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", _vm._n($$v))},expression:"form.amount"}})],1)]}}])})],1),_c('div',{staticClass:"column is-half-tablet py-0"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('death.weight'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('death.weight'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('p',{staticClass:"control w-100"},[_c('b-input',{attrs:{"id":"weight","type":"number","step":"any","placeholder":"0.0"},nativeOn:{"keyup":function($event){return _vm.updateAmount.apply(null, arguments)}},model:{value:(_vm.form.weight),callback:function ($$v) {_vm.$set(_vm.form, "weight", _vm._n($$v))},expression:"form.weight"}})],1),_c('p',{staticClass:"control"},[_c('b-button',{staticClass:"btn-placeholder",attrs:{"type":"is-text"}},[_vm._v("gram")])],1)])]}}])})],1)]),_c('ValidationProvider',{attrs:{"name":_vm.$t('desc'),"rules":"required|max:40"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('desc'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"text","placeholder":("Input " + (_vm.$t('desc')))},model:{value:(_vm.form.note),callback:function ($$v) {_vm.$set(_vm.form, "note", $$v)},expression:"form.note"}})],1)]}}])})],1)])],1),_c('footer',{staticClass:"has-text-right"},[(_vm.isModal)?_c('b-button',{attrs:{"type":"is-secondary is-light"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t('cancel')))]):_vm._e(),_c('b-button',{staticClass:"ml-2",attrs:{"type":"is-primary is-gradient"},on:{"click":_vm.handleSubmit}},[_vm._v(_vm._s(_vm.$t('save')))])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }