<template>
  <div class="card" :class="{'is-shadowless': !isModal}">
    <div class="card-content">
      <header class="mb-5">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <h2 class="is-size-6 has-text-primary has-text-weight-bold mr-3">{{ $t('death.death') }}</h2>
          <div @click="$emit('close')" v-if="isModal">
            <b-icon class="cursor-pointer" icon="close"/>
          </div>
        </div>
        <div>
          <p class="has-text-secondary mb-3">{{ selectedFarm.name }}</p>
        </div>
      </header>
      <section class="my-2">
        <ValidationObserver ref="form">
          <form class="mt-3" @submit.prevent="handleSubmit">
            <div class="columns is-tablet">
              <div class="column is-half-tablet py-0">
                <ValidationProvider :name="$t('farm.block')" rules="required" v-slot="{ errors }">
                  <b-field :label="$t('farm.block')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <sg-select :items="selectedFarm.blocks" type="is-secondary is-light"
                               :disabled="defaultValue ? true : false"
                               label="block_name"
                               v-model="form.block"
                               @change="form.pond = null"
                               :placeholder="`${$t('select')} ${$t('farm.block')}`" expanded/>
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column is-half-tablet py-0">
                <ValidationProvider :name="$t('farm.pond')" rules="required" v-slot="{ errors }">
                  <b-field :label="$t('farm.pond')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <sg-select :items="filteredPonds" type="is-secondary is-light" v-model="form.pond"
                               :disabled="defaultValue ? true : false"
                               label="name"
                               @change="onChangePond"
                               :placeholder="`${$t('select')} ${$t('farm.pond')}`" expanded/>
                  </b-field>
                </ValidationProvider>
              </div>

              <div class="column is-half-tablet py-0">
                <ValidationProvider :name="$t('date')" rules="required" v-slot="{ errors }">
                  <b-field :label="$t('date')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-datepicker
                        :placeholder="$t('date')"
                        icon="calendar-text"
                        locale="id-ID"
                        @input="handleDoc"
                        v-model="form.date">
                    </b-datepicker>
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column is-half-tablet py-0">
                <b-field label="DOC">
                  <b-input disabled v-model="doc"/>
                </b-field>
              </div>

              <div class="column is-full py-0">
                <ValidationProvider name="MBW" rules="required" v-slot="{ errors }">
                  <b-field label="MBW" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input v-model.number="form.mbw" id="mbw" type="number" step="any" placeholder="0.0"
                             @keyup.native="form.weight ? updateAmount() : updateWeight()"/>
                  </b-field>
                </ValidationProvider>
              </div>

              <div class="column is-half-tablet py-0">
                <ValidationProvider :name="$t('death.shrimp')" rules="required" v-slot="{ errors }">
                  <b-field :label="$t('death.shrimp')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input v-model.number="form.amount" id="amount" type="number" step="any" placeholder="0.0"
                             @keyup.native="updateWeight"/>
                  </b-field>
                </ValidationProvider>
              </div>

              <div class="column is-half-tablet py-0">
                <ValidationProvider :name="$t('death.weight')" rules="required" v-slot="{ errors }">
                  <b-field :label="$t('death.weight')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <p class="control w-100">
                      <b-input v-model.number="form.weight" id="weight" type="number" step="any" placeholder="0.0"
                               @keyup.native="updateAmount"/>
                    </p>
                    <p class="control">
                      <b-button type="is-text" class="btn-placeholder">gram</b-button>
                    </p>
                  </b-field>
                </ValidationProvider>
              </div>
            </div>

            <ValidationProvider :name="$t('desc')" rules="required|max:40" v-slot="{ errors }">
              <b-field :label="$t('desc')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input v-model="form.note" type="text" :placeholder="`Input ${$t('desc')}`"/>
              </b-field>
            </ValidationProvider>

          </form>

        </ValidationObserver>
      </section>
      <footer class="has-text-right">
        <b-button type="is-secondary is-light" @click="$emit('close')" v-if="isModal">{{ $t('cancel') }}</b-button>
        <b-button type="is-primary is-gradient" class="ml-2" @click="handleSubmit">{{ $t('save') }}</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import SgSelect from "@/components/Sg/SgSelect";
import {CometChat} from "@cometchat-pro/chat";
import {mapState} from "vuex";

export default {
  name: "InputDeathCount",
  components: {SgSelect},
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      type: Object,
      required: false,
    },
  },
  computed: {
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },
    filteredPonds() {
      if (this.defaultValue) return this.selectedFarm.ponds.filter(e => e.pond_id === this.defaultValue.pond_id)
      if (this.form.block && this.selectedFarm.ponds) return this.selectedFarm.ponds.filter(e => e.block_id === this.form.block.block_id && e.status === 2)
      else return []
    },
    ...mapState('user', [
      'cometChatUser',
      'cometChatLoggedIn',
    ]),
    guid() {
      return `sgara${this.selectedFarm.farm_id}`
    },
  },
  data: () => {
    return {
      form: {
        block: null,
        pond: null,
        weight: null,
        amount: null,
        mbw: null,
        stock: null,
      },
      images: [],
      doc: '-',
    }
  },
  watch: {
    'form.images': {
      deep: true,
      handler(values) {
        this.images = []

        if (values && values.length) {
          for (let i = 0; i < values.length; i++) {
            const file = values[i];
            this.images.push(URL.createObjectURL(file));
          }
        }
      }
    }
  },
  created() {
    this.resetForm()
  },
  methods: {
    handleMbw() {
      this.form.mbw = this.form.amount && this.form.weight ? this.form.amount / this.form.weight : 0
    },

    updateAmount() {
      if (this.form.mbw && this.form.weight)
        this.form.amount = Math.floor(this.form.weight / this.form.mbw)
    },

    updateWeight() {
      if (this.form.mbw && this.form.amount)
        this.form.weight = this.form.amount * this.form.mbw
    },

    resetForm() {
      if (this.defaultValue) {
        this.form.block = this.selectedFarm.blocks.find(e => e.block_id === this.defaultValue.block_id)
        this.form.pond = this.filteredPonds.find(e => e.pond_id === this.defaultValue.pond_id)
        this.form.date = new Date(this.defaultValue.created_at)
        this.form.amount = this.defaultValue.amount
        this.form.weight = this.defaultValue.weight
        this.form.note = this.defaultValue.note
        this.handleMbw()
        this.onChangePond()
      }
    },

    handleDoc() {
      this.doc = '-'
      if (this.form.stock && this.form.date) this.doc = this.$getDOC(this.form.stock.stock_date, this.form.date)
    },

    async onChangePond() {
      if (this.form.pond && this.form.pond.pond_id) {
        this.$loading()
        this.form.stock = await this.$store.dispatch('farm/getActiveStock', this.form.pond.pond_id)
        this.handleDoc()
        this.$loading(false)
      }
    },

    handleSubmit() {
      this.$refs['form'].validate().then(async success => {
        if (success) {
          let pond = this.form.pond

          this.$loading()

          if (this.defaultValue && this.defaultValue.death_id) {
            await this.$store.dispatch('farm/updateDeath', {
              stock_id: this.defaultValue.stock_id,
              amount: this.form.amount,
              weight: this.form.weight,
              mbw: this.form.mbw,
              note: this.form.note,
              created_at: this.$timestamp(this.form.date),
              death_id: this.defaultValue.death_id
            })
          } else {
            let stock = await this.$store.dispatch('farm/getActiveStock', pond.pond_id)
            if (stock) {
              await this.$store.dispatch('farm/addDeath', {
                stock_id: stock.stock_id,
                amount: this.form.amount,
                weight: this.form.weight,
                mbw: this.form.mbw,
                note: this.form.note,
                created_at: this.$timestamp(this.form.date),
                farm_id: this.form.block.farm_id,
                block_id: this.form.block.block_id,
                timediff: new Date().getTimezoneOffset() * -1,
              })
            }
          }
          // this.sendMessage('MBW' + ' ' + this.form.mbw + ' Jumlah Udang ' + this.form.amount + ' ' + this.form.pond.name + ' tanggal ' + this.$dateFormat(this.form.date))

          this.form = {}
          this.$emit('close')
          this.$loading(false)
          this.$store.dispatch('farm/refreshOverview')
        }
      })
    },
    // sendMessage(text) {
    //   if (this.cometChatLoggedIn) {
    //     let tag = 'Kematian'
    //
    //     let receiverID = this.guid;
    //     let receiverType = CometChat.RECEIVER_TYPE.GROUP;
    //
    //     let messageText = `<b>${this.cometChatUser.name}</b>` + " baru saja menginput data " + tag + ' ' + text;
    //     let textMessage = new CometChat.TextMessage(receiverID, messageText, receiverType);
    //
    //     textMessage.setTags([tag])
    //
    //     CometChat.sendMessage(textMessage).then(
    //         message => {
    //           console.log("Message sent successfully:", message);
    //         }, error => {
    //           console.log("Message sending failed with error:", error);
    //         }
    //     );
    //   }
    // },
  }
}
</script>

<style scoped lang="scss">
.card {
  width: 720px !important;
  max-width: 100%;
}
</style>

