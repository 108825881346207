<template>
  <div>
    <div class="is-flex is-justify-content-end">
      <b-button type="is-primary"
                class="has-shadow is-gradient mb-3"
                @click="addDeath"
                v-if="canAddDeath && !hideAction">
        {{ $t('add') }} Data
      </b-button>
    </div>

    <b-table :mobile-cards="false" :data="data" sticky-header>
      <template #empty>
        <p class="has-text-centered">{{ $t('no') }} Data</p>
      </template>

      <p class="has-text-weight-bold has-text-dark is-size-5 mb-2" v-if="dateLabel">{{ dateLabel }}</p>

      <b-table-column field="pond" :label="$t('farm.pond')" v-slot="props">
        {{ props.row.pond_name }}
      </b-table-column>
      <b-table-column field="created_at" :label="$t('date')" v-slot="props">
        {{ $dateFormat(props.row.created_at) }}
      </b-table-column>
      <b-table-column field="doc" label="DOC" v-slot="props">
        {{ props.row.stock ? $getDOC(props.row.stock.stock_date, props.row.created_at) : '-' }}
      </b-table-column>
      <b-table-column field="mbw" label="MBW" v-slot="props">
        {{ $numberFormat(props.row.mbw) }}
      </b-table-column>
      <b-table-column field="weight" :label="$t('death.weight')" v-slot="props">
        {{ $numberFormat(props.row.weight) }}
      </b-table-column>
      <b-table-column field="amount" :label="$t('death.shrimp')" v-slot="props">
        {{ $numberFormat(Math.floor(props.row.amount)) }}
      </b-table-column>
      <b-table-column field="cumulative" :label="$t('death.accumulative')" v-slot="props">
        <span class="has-text-danger">{{ $numberFormat(Math.floor(cumulativeDeath(props.row.pond_id, props.row.created_at))) }}</span>
      </b-table-column>
      <b-table-column field="note" :label="$t('desc')" v-slot="props">
        {{ props.row.note }}
      </b-table-column>

      <b-table-column field="action" label="" v-slot="props" v-if="canEditDeath && !hideAction">
        <div class="is-flex">
          <b-button type="is-secondary is-light" @click="editDeath(props.row)"
                    icon-left="pencil-outline"></b-button>
          <b-button class="ml-2" type="is-danger is-light" @click="deleteDeath(props.row.death_id)" v-if="!hideDelete"
                    icon-left="trash-can-outline"></b-button>
        </div>
      </b-table-column>
    </b-table>

    <!--  Modal Death -->

    <b-modal
        v-model="isModalOpen"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        v-if="!hideAction"
    >
      <template #default="props">
        <input-death-count is-modal @close="props.close" @submit="getData(true)" :default-value="death_form"/>
      </template>
    </b-modal>

    <div class="my-3" v-if="!hideChart">
      <div class="columns is-tablet">
        <div class="column is-half-tablet" v-for="(chart,key) in chartData" :key="key">
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">
                {{ chart.pond_name }}
              </p>
            </header>
            <div class="card-content">
              <apexchart v-if="chart.options && chart.series"
                         type="area"
                         height="350"
                         :options="chart.options"
                         :series="chart.series"></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputDeathCount from "@/components/Farm/Sidebar/InputDeathCount";
import VueApexCharts from "vue-apexcharts";
import {mapGetters} from "vuex";
import {toPng} from "html-to-image";
import {CometChat} from "@cometchat-pro/chat";

export default {
  name: "DeathCount",
  components: {
    InputDeathCount,
    apexchart: VueApexCharts,
  },
  props: {
    hideDelete: Boolean,
    hideAction: Boolean,
    hideChart: Boolean,
    dateLabel: String,
    date: Number,
  },
  data: () => {
    return {
      data: [],
      death_form: null,
      isModalOpen: false,
      chartData: [],
      sent: false,
    }
  },
  computed: {
    ...mapGetters('farm', [
      'canAddDeath',
      'canEditDeath',
    ]),
    ponds() {
      return this.$store.state.farm.overview.ponds
    },
    refresh_data() {
      return this.$store.state.farm.overview.refresh_data
    }
  },
  watch: {
    refresh_data(value) {
      if (value) this.getData(true)
    },
  },
  created() {
    this.getData(true)
  },
  methods: {
    addDeath() {
      this.death_form = null
      this.isModalOpen = true
    },
    async getData(refresh = false) {
      this.$loading()
      this.data = []

      let stock_ids = []

      for (let i = 0; i < this.ponds.length; i++) {
        let pond = this.ponds[i]

        if (!pond.stock) {
          pond.stock = await this.$store.dispatch('farm/getStockOverview', pond)
        }

        stock_ids = stock_ids.concat(pond.stock.map(e => e.stock_id))

        if (pond.selected_cycle) {
          if (refresh || !pond.death) {
            pond.death = []

            let stock = pond.stock.find(e => e.cycle_id === pond.selected_cycle.cycle_id)

            if (stock) {
              let death = await this.$store.dispatch('farm/getDeath', stock.stock_id)

              if (!death.error && death.data && Array.isArray(death.data)) {
                pond.death = death.data.map(e => {
                  e.pond_id = pond.pond_id
                  e.pond_name = pond.name
                  e.block_id = pond.block_id
                  e.block_name = pond.block_name
                  e.stock = stock
                  return e
                })
              }
            }
          }
        }
      }

      this.data = this.ponds.reduce((a, b) => {
        if (b.death) a = a.concat(b.death)
        return a
      }, []).sort((a, b) => {
        if (a.pond_name !== b.pond_name) {
          return a.pond_name.localeCompare(b.pond_name, undefined, {
            numeric: true,
            sensitivity: 'base',
          })
        }
        return b.created_at > a.created_at ? 1 : -1
      })
      this.generateChart()
      this.$loading(false)

      this.$emit('loaded')

      this.$store.dispatch('farm/refreshOverview', false)
    },

    generateChart() {
      this.chartData = this.ponds.reduce((a, b) => {
        if (b.death && b.death.length) {
          let stock = b.death[0].stock
          a.push({
            pond_name: b.name,
            series: [{
              name: this.$t('death.shrimp'),
              data: b.death.sort((a, b) => a.created_at > b.created_at ? 1 : -1).reduce((a, b) => {
                let find = a.find(e => e.x === b.created_at)

                if (!find)
                  a.push({
                    x: b.created_at,
                    y: this.$number(b.amount)
                  })

                return a
              }, [])
            }, {
              name: this.$t('death.weight'),
              data: b.death.sort((a, b) => a.created_at > b.created_at ? 1 : -1).reduce((a, b) => {
                let find = a.find(e => e.x === b.created_at)

                if (!find)
                  a.push({
                    x: b.created_at,
                    y: this.$number(b.weight)
                  })

                return a
              }, [])
            }],
            options: {
              chart: {
                type: 'area',
                height: 350,
                zoom: {
                  enabled: true
                }
              },
              colors: ['#FF4060', '#3998EB'],
              dataLabels: {
                enabled: false
              },
              stroke: {
                curve: 'smooth'
              },

              xaxis: {
                type: 'datetime',
              },
              legend: {
                horizontalAlign: 'center',
                itemMargin: {
                  horizontal: 5,
                  vertical: 10
                },
              },
              tooltip: {
                x: {
                  formatter: (value) => {
                    return this.$dateFormat(value, 'DD MMM YYYY') + ' - DOC ' + (stock ? this.$getDOC(stock.stock_date, value) : '-')
                  }
                }
              }
            }
          })
        }
        return a
      }, []).sort((a, b) => a.pond_name.localeCompare(b.pond_name, undefined, {
        numeric: true,
        sensitivity: 'base',
      }))

      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },

    editDeath(death) {
      this.death_form = this._.cloneDeep(death)
      this.isModalOpen = true
    },
    deleteDeath(id) {
      this.$delete(async () => {
        this.$loading()
        await this.$store.dispatch('farm/deleteDeath', id)
        await this.getData(true)
        this.$loading(false)
      })
    },

    cumulativeDeath(pond_id, date) {
      return this.data.reduce((a, b) => {
        if (b.pond_id === pond_id && b.created_at < date)
          return a + b.amount
        return a
      }, 0)
    },
  }
}
</script>
